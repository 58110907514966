<template>
  <div class="login-container">
    <div class="title-box">
      <a href="/"><font-awesome-icon icon="fa-solid fa-chevron-left" />홈</a>
      <h2>프로필</h2>
      <!-- <b-button class="settingbtn" @click="onShare()">
        <font-awesome-icon icon="fa-solid fa-gear" />
      </b-button> -->
    </div>

    <div class="text-center" v-if="naverUserName == null">
      <a v-on:click="login()" href="#"
        ><img height="50" src="http://static.nid.naver.com/oauth/small_g_in.PNG"
      /></a>
    </div>

    <div v-else style="width: 95%; margin: auto;outline: 1px solid white;">
      <div
        class="my-info"
        style="box-sizing: border-box;display: flex;padding: 20px 20px;align-items: center;border-bottom: 1px solid #efefef;"
      >
        <div
          class="profile-pic"
          style="width: 80px;height: 80px;border-radius: 50%;background: #FFBCAD;text-align: center;line-height: 80px; margin-right: 7%; font-size: 40px;color: #fff;"
        >
          <font-awesome-icon icon="fa-solid fa-face-laugh-beam" />
        </div>
        <div
          class="contact"
          style="text-align: left;color: #333;font-size: 14px;"
        >
          <strong style="font-size: 23px;font-weight: 700;">{{
            naverUserName
          }}</strong>
          <p><span>📞 </span>
<!--            010-7***-4*** -->
            {{ maskingAsPhoneNumber(naverUser.mobile) }}</p>
          <p><span>📫 </span>
<!--            ke******@g******.com -->
            {{ maskingAsEmail(naverUser.email) }} </p>
        </div>
      </div>
      <input type="hidden" id="naver_id" :value="naverUser.id" />
      <div
        class="gender-birth"
        style="box-sizing: border-box;padding: 20px 20px;border-bottom: 1px solid #efefef;"
      >
        <h3
          style="font-size: 18px;font-weight: 700;text-align: left;margin-bottom: 10px;"
        >
          내 정보
        </h3>
        <table>
          <tr>
            <th>성별</th>
            <td>{{ naverUser.gender }}</td>
          </tr>
          <tr>
            <th>생일</th>
            <td>{{ naverUser.birthday }}</td>
          </tr>
        </table>
      </div>
      <div
        class="social-login"
        style="box-sizing: border-box;padding: 20px 20px;border-bottom: 1px solid #efefef;"
      >
        <h3
          style="font-size: 18px;font-weight: 700;text-align: left;margin-bottom: 18px;"
        >
          연결된 계정
        </h3>
        <div class="accounts" style="display: flex;">
          <p class="naver on">
            <img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/btnG_%EC%95%84%EC%9D%B4%EC%BD%98%EC%82%AC%EA%B0%81.png"
              alt="네이버"
            />
          </p>
          <p class="kakao">
            <img
              src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/kakao_login_large_wide.png"
              alt="카카오톡"
            />
          </p>
        </div>
      </div>
    </div>

    <span v-if="error" style="color: #dc3545; font-size: 80%;">{{error}}</span>

    <br>
    <div v-if="naverUserName != null">
    <a href="/storage" class="btn btn-info">내 저장소 바로가기</a>
    </div>

    <span v-if="error" style="color: #dc3545; font-size: 80%;">{{
      error
    }}</span>

    <!-- <input
      type="button"
      value="NPay 결제내역 불러오기"
      onclick="showAndroidToast( document.getElementById('naver_id').value ,'https://www.naver.com')"
    /> -->
  </div>
</template>

<script>
import queryString from "querystring";
import axios from "axios";
import OAuthRequest from "@/Entities/OAuthRequest";
import linq from "linq";
import OAuthResult from "@/Entities/OAuthResult";

export default {
  name: "callback-naver",

  data() {
    return {
      error: "",
    };
  },
  props: {
    // clientId: {
    //     type: String,
    //     require: true
    // },
    //
    // clientSecret: {
    //     type: String,
    //     require: true
    // },
    //
    // callbackUri: {
    //     type: String,
    //     require: true
    // },
    //
    // addFriend: {
    //     type: Boolean,
    //     require: false
    // },
    //
    // friendRequired: {
    //     type: Boolean,
    //     require: false
    // },
    //
    // friendErrorTest: {
    //     type: String,
    //     require: false,
    //     default: 'Add me as a friend.'
    // },
    //
    loginText: {
      type: String,
      require: false,
      // default: 'LINE LOGIN'
      default: "로그인",
    },
  },

  async created() {
    //
    this.clientId = process.env.VUE_APP_NAVER_CLIENT_ID;
    this.clientSecret = process.env.VUE_APP_NAVER_CLIENT_SECRET;
    this.callbackUri = process.env.VUE_APP_NAVER_CALLBACK_URL;

    //this.$store.dispatch('line/getUserId');

    const { code } = queryString.parse(window.location.search.replace("?", ""));

    //if(!code) return

    // const { access_token } = localStorage.getItem("access_token")
    //
    // if(! access_token) return

    if (localStorage.getItem("naver_access_token") === null) {
      if (!code) return;
      const result = await this.getToken(code);

      // console.log("result:"+result)

      //save token local storage
      localStorage.setItem("naver_access_token", result.token);

      const { data } = this.$store.dispatch("naver/getNaverUser", result.token);
      //console.log("data:"+data)

      if (!this.error) {
        const response = Object.assign(data, result.getPostable());
        this.$emit("result", response);
      }
    } else {
      var access_token = localStorage.getItem("naver_access_token");
      //
      // console.log("access_token:"+access_token)
      //const {data} = await this.getProfile( access_token )
      //
      // //save state
      //this.$emit('result2', data.userId)

      this.$store.dispatch("naver/getNaverUser", access_token);
    }

    //this.$router.push('/naver')
  },
  computed: {
    naverUserName() {
      return this.$store.state.naver.naverUserName;
    },
    naverUser() {
      return this.$store.state.naver.naverUser;
    },
  },
  methods: {
    login() {
      localStorage.removeItem("naver_access_token");
      const url = "https://nid.naver.com/oauth2.0/authorize";
      let params = {
        response_type: "code",
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random()
          .toString(32)
          .substring(2, 16),
      };
      window.location.href = `${url}?${queryString.stringify(params)}`;
    },
    async getToken(code, state) {
      const result = new OAuthRequest({
        grant_type: "authorization_code",
        code: code,
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        redirectUri: this.callbackUri,
        state: state,
      });

      const params = new URLSearchParams();

      // console.log("before")
      linq
        .from(result.getPostable())
        .select((x) => params.append(x.key, x.value))
        .toArray();

      console.log("after param:" + params);
      const { data } = await axios.post("/oauth2.0/token", params);

      return new OAuthResult(data);
    },

    async getProfile(token) {
      return await axios.get("/v1/nid/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    async getNpay() {
      return await axios.get("/ca-fe");
    },
    maskingAsPhoneNumber(value) {
      value = value.replace(/\D/g, '');
      value = value.slice(0, 3) + '-' + value.slice(3, 4) + '***-' + value.slice(9,10)+"***";
      return value;
    },
    maskingAsEmail(value) {
      var maskid='';
      var prefix= value.substring(0, value.lastIndexOf("@"));
      var postfix= value.substring(value.lastIndexOf("@"));

      for(var i=0; i<prefix.length; i++){
        if( i < 2) {   ////////
          maskid = maskid + prefix[i].toString();
        }
        else {
          maskid = maskid + "*";
        }
      }
      maskid =maskid +postfix;
      return maskid;
    }
  },
};
</script>

<style>
p {
  margin: 0;
}
table tr {
  height: 35px;
  vertical-align: middle;
}
table th {
  font-weight: 400;
  font-size: 16px;
  color: #8f8f8f;
  padding-right: 20px;
}
table td {
  text-align: left;
  font-size: 18px;
  color: #333;
}
.login-container {
  text-align: center;
  margin-bottom: 200px;
}
.h2-ele {
  margin-top: 30px;
  margin-bottom: 100px;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
.btn-social {
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-block {
  display: block;
  /* width: 100%; */
  width: 90%;
  margin: auto;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 5px;
  height: 50px;
}
.btn-line {
  /* color: #fff; */
  /* background-color: #00b900; */
  /* border-color: rgba(0,0,0,0.2); */
  color: #fff;
  background-color: #00b900;
  border-color: #00b900;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-social > :first-child {
  height: 32px;
  width: 32px;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  /* border-right: 1px solid rgba(0,0,0,0.2); */
  /* border-right: 2px solid #00b900; */
  border: none;
}
.fa-line {
  background: url(https://d.line-scdn.net/r/web/social-plugin/img/common/line_v3.png)
    3px 4px no-repeat;
  display: inline-block;
  width: 32px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-size: 26px 24px;
  /* margin: 5px 5px 5px 110px */
}

/* 타이틀 */
.title-box {
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  width: 93%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  line-height: 6.9444vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
/* 공유버튼 */
.title-box .settingbtn {
  margin: 0;
  background: none;
  border: none;
  padding: 0 2.7778vw;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.title-box .settingbtn svg {
  color: #333;
}
.social-login .accounts p {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #999;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  filter: grayscale(100);
}
.social-login .accounts p.on {
  filter: grayscale(0);
}
.social-login .accounts p img {
  height: 100%;
}
</style>
